@charset "UTF-8";
:root {
  --colour-diff-15: #F44336;
  --colour-diff-10: #FF8000;
  --colour-diff-5: #FFFF4E;
  --colour-diff-0: #FFFED0;
  --colour-diff0: #D4FED5;
  --colour-diff5: #A9E7A6;
  --colour-diff10: #64CF63;
  --colour-diff15: #00B500;
  --p: 10px;
  --tooltip-2: 50%;
  --tooltip-3: 50%;
  --tooltip-4: 50%;
  --tooltip-5: 50%;
  --tooltip-6: 50%;
  --tooltip-7: 50%;
  --tooltip-8: 50%;
}
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colour-main, #efefef);
  background-image: var(--image-background, none);
  background-size: var(--background-size, 100%);
  background-repeat: var(--background-repeat, repeat-y);
  background-position: var(--background-position, 0 -100px);
  color: var(--colour-text, #000000);
  font-family: var(--serif-stack, serif);
  overflow-y: scroll;
}
html.survey-thankyou {
  background-color: var(--colour-thankyou-background, var(--colour-main, #efefef));
  background-image: var(--thankyou-background-image, var(--image-background, none));
  background-size: var(--thankyou-background-size, var(--background-size, 100%));
  background-repeat: var(--thankyou-background-repeat, var(--background-repeat, repeat-y));
  background-position: var(--thankyou-background-position, var(--background-position, 0 -100px));
  color: var(--colour-thankyou-text, var(--colour-text, #000000));
}
body {
  margin: 0;
  padding: 40px;
  border: 0;
  display: grid;
  width: calc(100% - 80px);
  grid-template-columns: 320px calc(100% - 320px);
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 40px;
  min-height: calc(100vh - 80px);
}
.hide, .hidden {
  display: none !important;
}
.invisible {
  visibility: hidden !important;
}
.clear {
  clear: both;
}
.crisp {
  transform: translate(0, 0);
  shape-rendering: crispEdges;
}
header {
  grid-column: 1/3;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  background-color: var(--colour-main, #efefef);
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 20px 40px;
  margin: -40px -40px 0 -40px;
  box-shadow: var(--shadow, 0 0 20px #00000022);
  border-bottom: var(--border-content, none);
}
header span a, header span button {
  display: inline-block;
  width: auto;
  margin: 0 20px 0 0;
  padding: 10px 14px 9px 14px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: var(--colour-text, #000) !important;
  border-radius: var(--border-radius, 5px);
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
}
header span a:hover, header span button:hover {
  background-color: var(--colour-header-link-background-hover, #ffffff44);
}
header span a.thispage {
  background-color: var(--colour-header-link-background-thispage, #ffffff);
  cursor: default;
}
header span button {
  margin-right: 0;
}
header span span {
  font-family: var(--sans-serif-stack, sans-serif);
  margin: 0 20px 0 0;
}
footer {
  grid-column: 1/3;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: auto;
  width: 100%;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
  font-weight: 400;
  align-items: end;
  grid-column-gap: 40px;
}
footer .support {
  font-size: 1.1rem;
  font-weight: 700;
}
footer .support a {
  text-decoration: none;
}
div.footer_left {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: end;
  font-size: 0.7rem;
}
img.footer_logo {
  grid-column: 4/5;
  grid-row: 1/2;
  height: 70px;
  align-self: end;
  justify-self: end;
}
section {
  grid-column: 2/3;
  grid-row: 2/3;
  align-self: start;
  display: block;
  position: relative;
  background-color: #fff;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  border: var(--border-content, none);
  border-radius: var(--border-radius, 5px);
  box-shadow: var(--shadow, 0 0 20px #00000022);
  overflow: hidden;
}
section header {
  position: relative;
  z-index: unset;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  margin: -20px -20px 0 -20px;
  width: calc(100% + 40px);
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
section header h1 {
  grid-column: 1/2;
  grid-row: 1/2;
  padding: 20px;
}
section header nav {
  grid-column: 2/3;
  grid-row: 1/2;
  display: flex;
  margin: 0;
  width: auto;
  background-color: transparent;
}
section header nav button {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  font-size: 20px;
  width: auto;
  padding: 20px 15px;
  color: #000;
  cursor: pointer;
  position: relative;
}
section header nav button:first-of-type {
  padding-left: 20px;
}
section header nav button:last-of-type {
  padding-right: 20px;
}
section header nav button span.disabled {
  cursor: not-allowed;
  color: #bbb;
}
section header nav button span.mono {
  font-size: 1.2rem;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
  margin-right: 10px;
}
section header nav button span.mono.A, section header nav button span.mono.B {
  border-radius: 5px;
  padding: 2px 5px;
}
section header nav button span.mono.A {
  background-color: #fedb4a;
}
section header nav button span.mono.B {
  background-color: #c0ca33;
}
section header nav button.inert {
  cursor: default;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  padding: 20px 5px;
}
section header nav button .tooltip {
  visibility: hidden;
  content: attr(data-label);
  position: absolute;
  top: calc(100% - 10px);
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
  z-index: 99;
}
section header nav button:nth-last-child(1) .tooltip {
  right: 20px;
  transform: none;
}
section header nav button:nth-last-child(2) .tooltip {
  right: var(--tooltip-2);
}
section header nav button:nth-last-child(3) .tooltip {
  right: var(--tooltip-3);
}
section header nav button:nth-last-child(4) .tooltip {
  right: var(--tooltip-4);
}
section header nav button:nth-last-child(5) .tooltip {
  right: var(--tooltip-5);
}
section header nav button:nth-last-child(6) .tooltip {
  right: var(--tooltip-6);
}
section header nav button:nth-last-child(7) .tooltip {
  right: var(--tooltip-7);
}
section header nav button:nth-last-child(8) .tooltip {
  right: var(--tooltip-8);
}
section header nav button:hover .tooltip {
  visibility: visible;
}
section header .modal {
  margin: 0 20px 20px 20px;
  border: 1px solid #ccc;
  grid-column: 1/3;
  padding: 20px;
  border-radius: 5px;
}
section header .modal .count-table {
  width: fit-content;
}
section header .modal .counts-layout {
  display: flex;
  flex-wrap: wrap;
}
section header .modal .counts-layout > div {
  margin: 0 20px 20px 0;
}
section header .modal .group-label {
  margin: 0;
  font-size: 1.2rem;
  font-family: var(--sans-serif-stack, sans-serif);
}
section header .modal .group-label span {
  font-weight: 700;
  text-decoration-thickness: 3px;
  width: 25px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}
section header .modal .group-label.A span {
  background-color: #fedb4a;
}
section header .modal .group-label.B span {
  background-color: #c0ca33;
}
section .info {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}
section .info button {
  display: block;
  width: 100%;
  border: 0;
  margin: 40px 0 0 0;
  padding: 10px 20px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
main {
  box-sizing: border-box;
  grid-column: 1/3;
  grid-row: 2/3;
  align-self: start;
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
}
main.opaque {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px #00000022;
}
svg {
  /*background-color: #fbfbfb;*/
  overflow: visible;
}
svg#svg {
  width: 100%;
  margin-bottom: 20px;
}
svg.offscreen {
  position: fixed;
  top: 0;
  left: calc(100vw + 1000px);
}
canvas#canvas, canvas#canvas2, canvas#canvas3, canvas#canvasLegend {
  display: none;
}
h1 {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  font-size: 20px;
  display: block;
  margin: 0;
}
h2 {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin: 0 0 8px 0;
}
h2 em {
  font-weight: 300;
  font-style: normal;
}
h5 {
  display: inline-block;
  background-color: var(--colour-main, #efefef);
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin: 12px 0 4px 0;
}
h6 {
  font-family: var(--sans-serif-stack, sans-serif);
  text-decoration: underline;
  font-weight: 300;
  font-size: 16px;
  margin: 12px 0 4px 0;
  text-transform: uppercase;
}
h7 {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  font-size: 16px;
  margin: 12px 0 4px 0;
  font-style: italic;
}
h3 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #f6f6f6;
  padding: 6px 10px;
  margin: 8px 0 8px 0;
}
h3:focus {
  outline: none;
}
h4 {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 20px 0 0 0;
}
p {
  display: block;
  margin: 0 0 4px 0;
  max-width: 100%;
}
a {
  color: var(--colour-text, #000);
}
ol, ul {
  list-style: none;
}
hr {
  display: block;
  width: 100%;
  height: 6px;
  background-color: #eee;
  border: none;
  margin: 16px 0;
}
strong {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
}
img.sample {
  float: left;
  border: 1px solid var(--colour-main, #efefef);
  margin: 10px 10px 0 0;
  width: 100px;
}
p.endnote {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 400;
  font-size: 0.95rem;
}
p.endnote a {
  color: #000;
}
span.muted {
  font-weight: 700;
}
span.boldcaps {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
}
.small-caps {
  text-transform: lowercase;
  font-variant: small-caps;
}
table {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #000;
  border-collapse: collapse;
  margin: 0 0 20px 0;
}
table tr:nth-child(even) {
  background-color: #f6f6f6;
}
table th {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 20px 8px 6px 8px;
}
table th:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #000;
}
table td {
  padding: 6px 8px;
  font-family: var(--monospace-stack, monospace);
}
table.count-table {
  border-bottom: none;
  text-align: center;
  margin-bottom: 0;
}
table.count-table td.gender {
  text-align: right;
  font-weight: 700;
}
table.count-table td.grade {
  font-weight: 700;
}
table.count-table td.grade-total {
  font-weight: 700;
  border-top: 1px solid #000;
}
table.count-table td.gender-total {
  font-weight: 700;
  border-left: 1px solid #000;
}
div.cp-table-of-counts {
  display: block;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #efefef;
  border: 1px solid #000;
  z-index: 999;
  margin-bottom: 0;
}
html.survey-login, html.survey-thankyou {
  overflow-x: hidden;
  overflow-y: hidden;
}
html.survey-login h3, html.survey-thankyou h3 {
  display: inline-block;
  background-color: var(--colour-main, #efefef);
}
html.survey-login p, html.survey-thankyou p {
  width: 700px;
  margin-bottom: 1rem;
}
html.survey-login div.fineprint, html.survey-thankyou div.fineprint {
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  font-size: 10px;
}
html.survey-login span.thankyou-head, html.survey-thankyou span.thankyou-head {
  display: block;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 3rem;
}
html.survey-login span.thankyou-message, html.survey-thankyou span.thankyou-message {
  display: block;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0 0 40px 0;
}
html.survey-login a.telephone, html.survey-thankyou a.telephone {
  display: inline-block;
  background-color: var(--colour-thankyou-telephone-background, #ffffff44);
  text-decoration: none;
  color: #000 !important;
  padding: 0 3px;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
}
html.survey-login img.thankyou-logo, html.survey-thankyou img.thankyou-logo {
  display: block;
  width: 30%;
  margin: 40px auto 0 auto;
}
html.survey-login img.squiggle, html.survey-thankyou img.squiggle {
  display: block;
  width: 50%;
  margin: 4em auto;
}
html.survey-login span.loginlabel, html.survey-thankyou span.loginlabel {
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
html.survey-login span.loginerror, html.survey-thankyou span.loginerror {
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
  display: block;
  overflow: hidden;
  color: var(--colour-error, red);
  transition: height 0.3s ease;
}
html.survey-login .login input, html.survey-thankyou .login input {
  display: inline-block;
  width: 54px;
  margin: 0 0 0 0;
  border: 0;
  padding: 10px;
  font-size: 32px;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
  text-align: center;
  opacity: 0.8;
  z-index: -1 !important;
  text-transform: uppercase;
}
html.survey-login .login input:focus, html.survey-thankyou .login input:focus {
  outline: none;
  opacity: 1;
}
html.survey-login .login .input, html.survey-thankyou .login .input {
  display: inline-block;
  margin: 0 0 0 0;
  border: 0;
  padding: 10px;
  font-size: 32px;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
}
html.survey-login .login button, html.survey-thankyou .login button {
  display: block;
  width: auto;
  float: right;
  margin: 0 0 0 0;
  border: 0;
  padding: 10px 20px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
  font-weight: 700;
  background-color: var(--colour-login-button, #000);
  color: var(--colour-login-button-text, var(--colour-main, #efefef));
  cursor: pointer;
}
html.survey-login .login button:hover, html.survey-thankyou .login button:hover {
  background-color: var(--colour-login-button-hover, #000);
  color: var(--colour-login-button-text-hover, #fff);
}
html.survey-login .spinnerBox, html.survey-thankyou .spinnerBox {
  display: none;
  position: relative;
  width: 100%;
  padding: 30px 0;
  margin: -120px auto 0 auto;
  background-color: var(--colour-main-translucent, #efefef99);
  z-index: 99 !important;
}
html.survey-login .spinner, html.survey-thankyou .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 20px auto;
}
html.survey-login .double-bounce1, html.survey-login .double-bounce2, html.survey-thankyou .double-bounce1, html.survey-thankyou .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
html.survey-login .double-bounce2, html.survey-thankyou .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.login-field {
  background-color: var(--colour-login-background, #ffffff);
  color: var(--colour-login-text, #000000);
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@media (max-width: 600px) {
  html.survey-login, html.survey-thankyou {
    /* For tablets: */
  }
  html.survey-login div.centre-box, html.survey-thankyou div.centre-box {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    margin: 0 auto;
  }
  html.survey-login span.thankyou-head, html.survey-thankyou span.thankyou-head {
    font-size: 60px;
  }
  html.survey-login span.thankyou-message, html.survey-thankyou span.thankyou-message {
    font-size: 40px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  html.survey-login div.centre-box p, html.survey-thankyou div.centre-box p {
    font-size: 32px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  html.survey-login .login input, html.survey-thankyou .login input {
    width: 38px;
    padding: 10px;
    font-size: 24px;
  }
}
main.home {
  font-family: var(--sans-serif-stack, sans-serif);
}
main.home h2 {
  font-weight: 300;
  margin: 0 0 20px 0;
}
main.home .section {
  padding: 20px;
  margin: 0 0 40px 0;
  background-color: #fff;
  border-radius: var(--border-radius, 5px);
  box-shadow: var(--shadow, 0 0 20px #00000022);
  overflow: hidden;
  border: var(--border-content, none);
}
main.home .surveys {
  padding-top: 0;
}
main.home .surveys .table {
  display: grid;
  border: 0;
  margin: 0;
}
main.home .surveys .table .tr {
  grid-column: 1/7;
  border-radius: 5px;
}
main.home .surveys .table .tr.odd {
  background-color: #f6f6f6;
}
main.home .surveys .table .th {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 20px 8px 6px 8px;
}
main.home .surveys .table .td.rl, main.home .surveys .table .th.rl {
  text-align: right;
}
main.home .surveys .table .td {
  display: flex;
  padding: 10px;
  font-size: 0.95rem;
}
main.home .surveys .table .td.mono {
  font-family: var(--monospace-stack, monospace);
  font-size: 1rem;
}
main.home .surveys .table .td.rl {
  justify-content: flex-end;
}
main.home .surveys .table .td.no-padding {
  padding: 0;
}
main.home .surveys .table .td.vertical {
  flex-direction: column;
}
main.home .surveys .table .td .status {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  align-self: stretch;
  align-items: flex-start;
  text-decoration: none;
  opacity: 0.9;
  cursor: default;
}
main.home .surveys .table .td .status:hover {
  opacity: 1;
}
main.home .surveys .table .td .status > span {
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}
main.home .surveys .table .td .status.closed > span {
  background-color: var(--colour-status-closed, #efefef);
}
main.home .surveys .table .td .status.openingSoon > span {
  background-color: var(--colour-status-opening-soon, #efefef);
}
main.home .surveys .table .td .status.closedInsufficient > span {
  background-color: var(--colour-status-closed-insufficient, #ff6d00);
}
main.home .surveys .table .td .status.openSufficient > span {
  background-color: var(--colour-status-open, var(--colour-main-tint, #f6f6f6));
}
main.home .surveys .table .td .status.openInsufficient > span {
  background-color: var(--colour-status-open, var(--colour-main-tint, #f6f6f6));
}
main.home .surveys .table .td .status.closedSufficient > span {
  background-color: var(--colour-status-closed-sufficient, #9ccc65);
  cursor: pointer;
}
main.home .surveys p {
  font-size: 0.9rem;
}
a.offering {
  display: block;
  float: left;
  margin: 0 20px 20px 0;
  padding: 30px;
  font-family: var(--sans-serif-stack, sans-serif);
  text-decoration: none;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: var(--border-radius, 5px);
  box-shadow: var(--shadow, 0 0 20px #00000022);
  border: var(--border-content, none);
}
a.offering:hover span {
  text-decoration: underline;
}
a.offering i {
  display: inline-block;
  position: relative;
  bottom: -5px;
  font-size: 30px;
  margin: -20px 0 -5px 0;
}
div#no-survey-message {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 60px 20px;
  background-color: #efefef;
  text-align: center;
  margin: 20px 0 20px 0;
  font-family: var(--sans-serif-stack, sans-serif);
}
.loadingdots:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}
@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
.status-bar {
  display: block;
  width: calc(100% + 40px);
  height: 20px;
  margin: -10px -20px 10px -20px;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.status-bar .status-bar-segment {
  float: left;
  width: 20%;
  height: 20px;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}
.status-bar .closedSufficient {
  background-color: var(--colour-status-closed-sufficient, #9ccc65);
}
.status-bar .closedInsufficient {
  background-color: var(--colour-status-closed-insufficient, #ff6d00);
}
.status-bar .openSufficient {
  background-color: var(--colour-status-open, #ffe47a);
}
.status-bar .openInsufficient {
  background-size: 30px 30px;
  background-image: linear-gradient(45deg, var(--colour-status-open-tint, var(--colour-main-tint, #f6f6f6)) 25%, var(--colour-status-open, var(--colour-main, #efefef)) 25%, var(--colour-status-open, var(--colour-main, #efefef)) 50%, var(--colour-status-open-tint, var(--colour-main-tint, #f6f6f6)) 50%, var(--colour-status-open-tint, var(--colour-main-tint, #f6f6f6)) 75%, var(--colour-status-open, var(--colour-main, #efefef)) 75%, var(--colour-status-open, var(--colour-main, #efefef)));
}
.status-bar .openingSoon {
  background-color: #efefef;
}
.loading-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  /*background-color: #f6f6f6;*/
  background-size: 30px 30px;
  background-image: linear-gradient(45deg, #f6f6f6 25%, #efefef 25%, #efefef 50%, #f6f6f6 50%, #f6f6f6 75%, #efefef 75%, #efefef);
  animation: barberpole 2s linear infinite;
}
.loading-text {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--monospace-stack, monospace);
}
.loading-progress {
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 0;
  width: 50%;
  background-color: #efefef;
  -webkit-transition: width 0.2s;
  /* Safari prior 6.1 */
  transition: width 0.2s;
  box-sizing: border-box;
  border-right: 1px solid var(--colour-main, #efefef);
}
span.survey-detail {
  display: block;
  float: right;
  cursor: pointer;
  margin-left: 10px;
}
span.survey-clusters {
  display: block;
  font-size: 0.8rem;
  text-align: right;
}
span.survey-clusters span {
  font-weight: 700;
  display: block;
}
.survey-counts {
  display: block;
  float: right;
  font-weight: 300;
}
.survey-counts .number {
  font-family: var(--monospace-stack, monospace);
}
div.shades {
  display: table-cell;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  padding-top: 200px;
  background-color: var(--colour-shades, rgba(254, 219, 74, 0.9));
}
div.popup {
  display: inline-block;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  z-index: 99;
}
div.popup table {
  border-bottom: none;
  text-align: center;
}
div.popup table td.gender {
  text-align: right;
  font-weight: 700;
}
div.popup table td.grade {
  font-weight: 700;
}
div.popup table td.grade-total {
  font-weight: 700;
  border-top: 1px solid #000;
}
div.popup table td.gender-total {
  font-weight: 700;
  border-left: 1px solid #000;
}
div#cp {
  display: block;
  width: 100%;
  margin: 0 0 20px 0;
}
div#cp .cp-header {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
  padding: 0 20px;
  font-family: var(--sans-serif-stack, sans-serif);
}
div#cp .cp-header .cp-tab,
div#cp .cp-header .cp-button {
  padding: 6px 10px 8px 10px;
  cursor: pointer;
}
div#cp .cp-header .left {
  display: flex;
  justify-content: flex-start;
}
div#cp .cp-header .left .cp-tab {
  margin-right: 1rem;
  border: 3px solid #efefef;
  border-bottom: 0;
  font-family: var(--monospace-stack, monospace);
}
div#cp .cp-header .left .cp-tab:hover, div#cp .cp-header .left .cp-tab.active {
  background-color: #efefef;
}
div#cp .cp-body {
  margin: 0 -20px;
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
div#cp .cp-body .duplication {
  width: 100%;
}
div#cp .cp-footer {
  margin: 0 -20px;
  padding: 0 20px;
}
div#cp div.cp-dim {
  display: grid;
  grid-template-columns: 70px 300px auto;
  grid-template-rows: auto auto;
  padding: 0 0 20px 0;
}
div#cp div.cp-dim div.cp-step {
  grid-column: 1/2;
  grid-row: 1/3;
  background-color: #000;
  color: #fff;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 1.2rem;
}
div#cp div.cp-dim div.cp-instruction {
  grid-column: 2/3;
  grid-row: 1/2;
  padding: 0 10px 5px 0;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 1rem;
}
div#cp div.cp-dim div.cp-elaboration {
  grid-column: 2/3;
  grid-row: 2/3;
  padding: 0 10px 0 0;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #666;
}
div#cp div.cp-dim div.cp-inputbox {
  grid-column: 3/4;
  grid-row: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-bottom: -5px;*/
  background-color: #f6f6f6;
}
div#cp div.cp-dim div.cp-inputbox input[type=text] {
  display: block;
  outline: none;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  font-family: var(--monospace-stack, monospace);
  text-align: center;
  font-size: 1rem;
  background-color: transparent;
}
div#cp div.cp-dim div.cp-inputbox select {
  display: block;
  outline: none;
  border: 0;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  font-family: var(--monospace-stack, monospace);
  text-align: center;
  font-size: 1rem;
  background-color: transparent;
}
div#cp div.cp-dim div.cp-label {
  display: none;
}
div#cp.compact .cp-body[data-tab=surveys] {
  padding: 10px 20px;
}
div#cp.compact .cp-body[data-tab=responses],
div#cp.compact .cp-body[data-tab=homepage] {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #efefef;
  padding: 10px 10px 0 10px;
}
div#cp.compact .cp-footer {
  padding: 10px 20px 0 20px;
  border-bottom: 1px solid transparent;
}
div#cp.compact div#cp-toggle-compact::before {
  content: "Expand";
}
div#cp.compact .duplication {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
div#cp.compact .duplication:nth-of-type(1) .cp-filter-button.on {
  background-color: var(--cp-filter-button-on, #fedb4a);
}
div#cp.compact .duplication:nth-of-type(2) .cp-filter-button.on, div#cp.compact .duplication:nth-of-type(2) .select.multi .input .multiselected .value {
  background-color: #c0ca33;
}
div#cp.compact .duplication div.cp-dim {
  width: 50%;
}
div#cp.compact .duplication.duplicated div.cp-dim {
  width: 100%;
}
div#cp.compact .duplication.duplicated .group-label {
  margin: 0 10px 10px 10px;
  font-size: 1.2rem;
  font-family: var(--sans-serif-stack, sans-serif);
}
div#cp.compact .duplication.duplicated .group-label span {
  font-weight: 700;
  text-decoration-thickness: 3px;
  width: 25px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}
div#cp.compact .duplication.duplicated:nth-child(1) .group-label span {
  background-color: #fedb4a;
}
div#cp.compact .duplication.duplicated:nth-child(2) .group-label span {
  background-color: #c0ca33;
}
div#cp.compact div.cp-dim {
  grid-template-columns: 170px auto;
  grid-template-rows: auto;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0 10px 10px 10px;
}
div#cp.compact div.cp-dim div.cp-step,
div#cp.compact div.cp-dim div.cp-instruction,
div#cp.compact div.cp-dim div.cp-elaboration {
  display: none;
}
div#cp.compact div.cp-dim div.cp-inputbox {
  grid-column: 2/3;
  grid-row: 1/2;
  display: block;
}
div#cp.compact div.cp-dim div.cp-inputbox input[type=text] {
  text-align: left;
  padding: 5px 10px;
}
div#cp.compact div.cp-dim div.cp-inputbox select {
  text-align: left;
  padding: 5px 10px;
  width: 100%;
}
div#cp.compact div.cp-dim div.cp-label {
  display: flex;
  grid-column: 1/2;
  grid-row: 1/2;
  align-items: center;
  /*align-self: center;*/
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}
div.cp-filter-box,
div.cp-checkbox-box {
  display: block;
  margin: -10px 0 0 0;
}
div.cp-filter-button {
  padding: 5px 10px;
  margin: 0 0 0 0;
  float: left;
  background-color: #fff;
  font-family: var(--monospace-stack, monospace);
  cursor: pointer;
}
div.cp-filter-button.on {
  background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
}
div.cp-filter-button.disabled {
  background-size: 10px 10px;
  background-image: linear-gradient(-45deg, #f6f6f6 25%, #fff 25%, #fff 50%, #f6f6f6 50%, #f6f6f6 75%, #fff 75%, #fff);
  color: #666;
  cursor: default;
}
div.cp-filter-box div.cp-button-box {
  margin: 10px 0 0 0;
  background-color: #000;
  float: left;
}
div.cp-filter-box div.cp-button-box.divider {
  margin: 10px 0 0 0;
  background-color: transparent;
  float: left;
}
div.cp-filter-box div.cp-button-box.divider::before, div.cp-filter-box div.cp-button-box.divider::after {
  display: block;
  float: left;
  padding: 5px 0;
  margin: 0 0px;
  width: 5px;
  color: transparent;
  font-family: var(--monospace-stack, monospace);
  content: "&nbsp;";
  z-index: 1;
}
div.cp-filter-box div.cp-button-box.left div.cp-filter-button.on {
  background-color: var(--colour-female-translucent-flat, #b5e1a5);
}
div.cp-filter-box div.cp-button-box.right div.cp-filter-button.on {
  background-color: var(--colour-male-translucent-flat, #cda9f9);
}
div.cp-filter-button.F.on {
  background-color: #b5e1a5;
}
div.cp-filter-button.M.on {
  background-color: #cda9f9;
}
div.cp-filter-button.O.on {
  background-color: #ffb199;
}
div.cp-filter-button.P.on {
  background-color: #b4bdc0;
}
div.cp-checkbox {
  padding: 5px 10px;
  margin: 10px 10px 0 0;
  float: left;
  background-color: #fff;
  font-family: var(--monospace-stack, monospace);
  cursor: pointer;
}
div.cp-checkbox::after {
  content: " ☐";
  font-weight: 700;
  font-size: 0.85rem;
}
div.cp-checkbox.on::after {
  content: " ☑";
  font-size: 0.85rem;
}
div#cp-generate-pdf,
div#cp-retrieve-data,
div#ap-save,
div#ap-update,
div#ap-delete {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
  border: 1px solid var(--colour-cp-highlight, var(--colour-main, #efefef));
  cursor: pointer;
  text-align: center;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
div#cp-generate-pdf:hover,
div#cp-retrieve-data:hover,
div#ap-save:hover,
div#ap-update:hover,
div#ap-delete:hover {
  border: 1px solid #000;
}
div#cp-generate-pdf.disabled,
div#cp-retrieve-data.disabled,
div#ap-save.disabled,
div#ap-update.disabled,
div#ap-delete.disabled {
  background-color: #efefef;
  border: 1px solid #efefef;
  cursor: default;
}
div#cp-generate-pdf.disabled:hover, div#cp-generate-pdf.disabled:hover,
div#cp-retrieve-data.disabled:hover,
div#cp-retrieve-data.disabled:hover,
div#ap-save.disabled:hover,
div#ap-save.disabled:hover,
div#ap-update.disabled:hover,
div#ap-update.disabled:hover,
div#ap-delete.disabled:hover,
div#ap-delete.disabled:hover {
  border: 1px solid #efefef;
}
div#cp-generate-pdf.loading,
div#cp-retrieve-data.loading,
div#ap-save.loading,
div#ap-update.loading,
div#ap-delete.loading {
  background-size: 30px 30px;
  background-image: linear-gradient(45deg, var(--colour-cp-highlight-tint, var(--colour-main-tint, #f6f6f6)) 25%, var(--colour-cp-highlight, var(--colour-main, #efefef)) 25%, var(--colour-cp-highlight, var(--colour-main, #efefef)) 50%, var(--colour-cp-highlight-tint, var(--colour-main-tint, #f6f6f6)) 50%, var(--colour-cp-highlight-tint, var(--colour-main-tint, #f6f6f6)) 75%, var(--colour-cp-highlight, var(--colour-main, #efefef)) 75%, var(--colour-cp-highlight, var(--colour-main, #efefef)));
  animation: barberpole 2s linear infinite;
  cursor: default;
  color: transparent;
}
div#cp-generate-pdf:active,
div#cp-retrieve-data:active,
div#ap-save:active,
div#ap-update:active,
div#ap-delete:active {
  background-size: 30px 30px;
  background-image: linear-gradient(45deg, var(--colour-cp-highlight-tint, var(--colour-main-tint, #f6f6f6)) 25%, var(--colour-cp-highlight, var(--colour-main, #efefef)) 25%, var(--colour-cp-highlight, var(--colour-main, #efefef)) 50%, var(--colour-cp-highlight-tint, var(--colour-main-tint, #f6f6f6)) 50%, var(--colour-cp-highlight-tint, var(--colour-main-tint, #f6f6f6)) 75%, var(--colour-cp-highlight, var(--colour-main, #efefef)) 75%, var(--colour-cp-highlight, var(--colour-main, #efefef)));
  animation: barberpole 2s linear infinite;
}
div#cp-generate-pdf.disabled:active,
div#cp-retrieve-data.disabled:active,
div#ap-save.disabled:active,
div#ap-update.disabled:active,
div#ap-delete.disabled:active {
  background-color: #efefef;
  background-size: 30px 30px;
  background-image: none;
  animation: none;
}
@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
div.cp-footer-block {
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: auto;
  width: 100%;
  margin: 0 0 10px 0;
}
div.cp-icon {
  grid-template-columns: 1/2;
  grid-template-rows: 1/2;
  color: #000;
  align-self: center;
  text-align: center;
}
div.cp-text {
  grid-template-columns: 2/3;
  grid-template-rows: 1/2;
  box-sizing: border-box;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  font-family: var(--monospace-stack, monospace);
}
div.cp-error {
  background-color: #ff8a65;
}
div.cp-error div.cp-text {
  background-color: #ffccbc;
}
div.cp-error div.cp-icon {
  color: #dd2c00;
}
div.cp-warning {
  background-color: #ffb74d;
}
div.cp-warning div.cp-text {
  background-color: #ffe0b2;
}
div.cp-warning div.cp-icon {
  color: #e65100;
}
div.cp-info {
  background-color: #ddd;
}
div.cp-info div.cp-text {
  background-color: #efefef;
  font-size: 0.7rem;
  font-family: var(--sans-serif-stack, sans-serif);
}
div.cp-loading-bar {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  width: calc(100% + 40px);
  margin: 0 -20px;
  /*background-color: #f6f6f6;*/
  background-size: 40px 40px;
  background-image: linear-gradient(45deg, #f6f6f6 25%, #efefef 25%, #efefef 50%, #f6f6f6 50%, #f6f6f6 75%, #efefef 75%, #efefef);
  animation: barberpole 2s linear infinite;
}
div.cp-loading-text {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  z-index: 10;
  text-align: center;
  font-family: var(--monospace-stack, monospace);
}
div.cp-loading-progress {
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 0;
  width: 0%;
  background-color: #efefef;
  -webkit-transition: width 0.2s;
  /* Safari prior 6.1 */
  transition: width 0.2s;
  box-sizing: border-box;
  border-right: 1px solid var(--colour-main, #efefef);
}
[data-tab=surveys].cp-body {
  font-family: var(--monospace-stack, monospace);
}
div.survey-selector-controls {
  width: 100%;
  margin: 0;
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  color: #000;
}
div.survey-selector-text-container {
  flex-grow: 0;
}
div.survey-selector-search-container {
  padding: 0 0 0 40px;
  flex-grow: 1;
}
span.survey-selector-title {
  font-weight: 700;
}
span.survey-selector-instructions {
  font-size: 0.9rem;
  font-style: italic;
}
span.survey-selector-instructions span {
  text-decoration: underline;
  cursor: pointer;
}
div.survey-selector-search-container input {
  width: 100%;
  box-sizing: border-box;
}
div.cp-export-button {
  display: inline-block;
  padding: 5px 10px;
  font-family: var(--monospace-stack, monospace);
  cursor: pointer;
}
/* ============================================================================== */
/* ============================================================================== */
/* CUSTOM BUILT DROPDOWN ======================================================== */
/* ============================================================================== */
.curtain {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;
}
.select {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: var(--monospace-stack);
}
.select .input {
  display: grid;
  grid-template-columns: 1fr auto auto;
  box-sizing: border-box;
  height: 100%;
  background-color: #f6f6f6;
  cursor: pointer;
}
.select .input .selected {
  display: grid;
  align-items: center;
  padding: 8px 10px;
  font-size: 1rem;
  word-break: break-all;
}
.select .input .multiselected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 var(--h) var(--h) 0;
}
.select .input .multiselected .value {
  display: grid;
  grid-template-columns: auto auto;
  background-color: var(--c-highlight);
  margin: var(--h) 0 0 var(--h);
  border-radius: var(--h);
}
.select .input .multiselected .value .label {
  padding: var(--q) var(--h);
  font-size: 1rem;
  cursor: default;
}
.select .input .multiselected .value .unselect {
  padding: var(--q) var(--h);
  display: grid;
  align-items: center;
  border-left: 1px solid #00000022;
  color: #00000044;
}
.select .input .multiselected .value .unselect:hover {
  color: black;
}
.select .input .icon {
  padding: 5px;
  display: grid;
  align-items: center;
  color: var(--c-muted);
}
.select .input .icon:hover, .select .input .icon.black {
  color: black;
}
.select .input .icon.clear {
  display: none;
}
.select.open .input {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select .dropdown {
  display: block;
  position: absolute;
  width: 100%;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
  font-size: 1rem;
}
.select .dropdown input {
  display: block;
  padding: var(--p) !important;
  border: 0;
  width: 100%;
  border-bottom: 1px solid #bcbcbc !important;
  background-color: #fff !important;
}
.select .dropdown .options {
  max-height: 200px;
  overflow-y: auto;
}
.select .dropdown .options .heading {
  padding: var(--h) var(--p);
  background-color: var(--c-boundary);
  font-weight: 700;
}
.select .dropdown .options .option {
  padding: 10px;
}
.select .dropdown .options .option:hover {
  background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
  cursor: pointer;
}
.select .dropdown .options .option.disabled {
  color: var(--c-muted);
  cursor: default;
}
.select.multi .input {
  font-size: 1rem;
}
.select.multi .input .search {
  display: none;
  float: left;
  margin: 0 10px 10px 0;
}
.select.multi .input .search input[type=text] {
  display: block;
  outline: none;
  height: auto;
  box-sizing: border-box;
  font-family: var(--monospace-stack), monospace;
  font-size: 1rem;
  padding: 5px 10px;
}
.select.multi .input .multiselected {
  cursor: default;
}
.select.multi .input .multiselected .value {
  float: left;
  position: relative;
  margin: 0 10px 10px 0;
  padding: 5px 10px;
  max-width: 100%;
  background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
  color: #000;
  line-height: 1rem;
  cursor: pointer;
  font-family: var(--monospace-stack), monospace;
}
.select.multi .input .multiselected .value .label {
  font-size: 1rem;
  cursor: pointer;
}
.select.multi .input .multiselected .value .unselect {
  display: none;
}
.select.multi.empty .input .search {
  display: block;
  margin: 0;
}
.select.multi.open .input {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select.multi.open .input .search {
  display: block;
}
.select.multi .dropdown {
  border: none;
  border-top: 1px solid #000;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select.multi .dropdown .options {
  padding: 10px 10px 0 10px;
  background-color: #f6f6f6;
}
.select.multi .dropdown .options .option {
  display: inline-block;
  padding: 5px 10px;
  background-color: #fff;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 10px 10px 0;
  font-family: var(--monospace-stack), monospace;
}
.field-box > .select .input {
  background-color: var(--c-boundary);
}
span#download-pdf {
  cursor: pointer;
  text-decoration: underline;
}
span#download-pdf.disabled {
  cursor: not-allowed;
}
.reports {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-gap: 0px;
  padding-bottom: 20px;
}
.reports > a {
  display: grid;
  grid-gap: 40px;
  padding: 20px;
  grid-template-columns: 250px 1fr;
  font-family: var(--sans-serif-stack, sans-serif);
  text-decoration: none;
}
.reports > a .img {
  width: 100%;
  height: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--shadow, 0 0 10px #00000022);
  background-size: contain;
  background-position: center;
}
.reports > a .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reports > a .text h1 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.reports > a .text p {
  line-height: 1.5rem;
  max-width: 400px;
}
.reports > a .text [role=button] {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  align-self: flex-start;
  border-radius: 5px;
  font-weight: 700;
}
.reports > a.disabled {
  cursor: not-allowed;
}
.reports > a:hover .text [role=button] {
  color: #fedb4a;
}
.reports > a:hover.disabled .text [role=button] {
  color: #fff;
}
@media (max-width: 1450px) {
  .reports {
    grid-template-columns: 1fr;
  }
}
main.resources {
  padding: 40px;
}
main.resources div.banner {
  background-color: var(--colour-main, #efefef);
  padding: 40px;
  border-radius: 5px;
  margin: 0 0 40px 0;
  display: flex;
  align-items: space-between;
}
main.resources div.banner h1 {
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 4rem;
  font-weight: 700;
  line-height: 3.6rem;
  letter-spacing: 0px;
}
main.resources .search {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
main.resources .search span {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 1.2rem;
}
main.resources .search input {
  display: block;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 20px;
  outline: none;
  font-family: var(--monospace-stack, monospace);
  font-size: 1rem;
}
main.resources .resources-wrapper {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 40px)/3));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
main.resources .resources-wrapper a.resource {
  display: block;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  padding: 20px;
  font-family: var(--sans-serif-stack, sans-serif);
  text-decoration: none;
  font-weight: 700;
}
main.resources .resources-wrapper a.resource:hover {
  border-color: #000;
}
nav {
  grid-column: 1/2;
  grid-row: 2/3;
  display: block;
  background-color: var(--colour-main-translucent, #efefef99);
  width: 300px;
  margin: 0 20px 0 0;
}
nav hr {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 0.6;
  border: none;
  margin: 10px 0;
}
.survey-offering-selector {
  position: relative;
  width: calc(100% - 10px);
  margin: 0 0 10px 10px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  color: var(--colour-main, #efefef);
  background-color: transparent !important;
}
.survey-offering-selector::selection {
  background-color: var(--colour-main, #efefef) !important;
  color: #000 !important;
}
.survey-offering-selector .input {
  border-radius: var(--border-radius, 5px);
}
.survey-offering-selector .input .selected {
  padding: 6px 10px 8px 10px;
  cursor: pointer;
  background-color: var(--colour-nav-survey-background, #000000);
  border-radius: var(--border-radius, 5px);
  font-size: 0.95rem;
  font-family: var(--sans-serif-stack, sans-serif);
}
.survey-offering-selector .input .selected:before {
  background-color: var(--colour-main, #efefef);
  -webkit-mask-image: url(plus-solid.73d72aae.svg);
  mask-image: url(plus-solid.73d72aae.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center !important;
  mask-position: center !important;
  position: absolute;
  content: "";
  top: 9px;
  right: 10px;
  background-position: 0 0;
  width: 12px;
  height: 12px;
}
.survey-offering-selector .input .selected:after {
  content: "Survey";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}
.survey-offering-selector .input .selected.value-placeholder:after {
  content: "To select a survey";
}
.survey-offering-selector .input .icon {
  display: none;
}
.survey-offering-selector .select.open .input .selected:before {
  -webkit-mask-image: url(minus-solid.85de8650.svg);
  mask-image: url(minus-solid.85de8650.svg);
}
.survey-offering-selector .dropdown {
  border: 0;
  position: relative;
  background-color: transparent;
  box-shadow: none;
}
.survey-offering-selector .dropdown .options {
  position: relative;
  background-color: transparent;
  z-index: 99;
  margin-top: 3px;
  border-radius: var(--border-radius, 5px);
  max-height: none;
  overflow-y: auto;
}
.survey-offering-selector .dropdown .options .option {
  padding: 6px 10px 8px 10px;
  cursor: pointer;
  background-color: var(--colour-nav-survey-background, #000000);
  font-size: 0.95rem;
  font-family: var(--sans-serif-stack, sans-serif);
}
.survey-offering-selector .dropdown .options .option:hover {
  color: #fff;
  background-color: var(--colour-nav-survey-background, #000000);
}
.survey-offering-selector .dropdown .options .option.disabled {
  cursor: default;
  background-color: var(--colour-nav-survey-background-translucent, #00000066);
}
.survey-offering-selector .dropdown .options .option.disabled:hover {
  color: var(--colour-main, #efefef);
}
.survey-offering-selector .dropdown .options .option:after {
  content: "Survey";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}
.survey-offering-selector .dropdown .options .option.active, .survey-offering-selector .dropdown .options .option.value-placeholder {
  display: none;
}
div.menu-type-box {
  display: block;
  margin: 0 0 0 20px;
}
div.menu-type {
  float: left;
  font-family: var(--sans-serif-stack, sans-serif);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.7px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 0 0 0px 0;
  cursor: pointer;
}
div.menu-type.current-type {
  border-bottom: 4px solid var(--colour-nav-border, #fff);
  cursor: default;
}
ul.cd-accordion-menu {
  clear: both;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: -6px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 400;
  font-size: 0.95rem;
}
ul.cd-accordion-menu strong {
  font-weight: 700;
}
ul.cd-accordion-menu strong strong {
  font-weight: 400;
}
ul.cd-accordion-menu ul {
  /* by default hide all sub menus */
  display: none;
  padding: 0;
  margin-left: 20px;
  border-left: 4px solid var(--colour-nav-border, #fff);
}
ul.cd-accordion-menu ul ul {
  /* by default hide all sub menus */
  padding: 0;
  margin-left: 28px;
  border-left: 1px solid var(--colour-nav-border, #fff);
}
ul.cd-accordion-menu ul ul label {
  padding-left: 28px;
}
ul.cd-accordion-menu ul ul label::before {
  left: 28px;
}
ul.cd-accordion-menu ul ul a {
  padding-left: 12px;
}
ul.cd-accordion-menu ul ul ul label {
  padding-left: 28px;
}
ul.cd-accordion-menu ul ul ul label::before {
  left: 28px;
}
ul.cd-accordion-menu ul ul ul a {
  padding-left: 12px;
}
ul.cd-accordion-menu ul label {
  padding-left: 28px;
}
ul.cd-accordion-menu ul label::before {
  left: 28px;
  background: var(--colour-nav-border, #fff);
}
ul.cd-accordion-menu ul a {
  padding-left: 12px;
}
ul.cd-accordion-menu li {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
ul.cd-accordion-menu input[type=checkbox] {
  /* hide native checkbox */
  position: absolute;
  opacity: 0;
}
ul.cd-accordion-menu label,
ul.cd-accordion-menu a {
  position: relative;
  display: block;
  padding: 4px 6px 4px 20px;
  color: var(--colour-text, #000000);
  text-decoration: none;
}
ul.cd-accordion-menu a:hover {
  text-shadow: 2px 2px 0px var(--colour-nav-border, #fff);
}
ul.cd-accordion-menu label {
  font-weight: 700;
  cursor: pointer;
}
ul.cd-accordion-menu label strong {
  font-weight: 300;
}
ul.cd-accordion-menu label::before {
  /* icons */
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  margin-left: -22px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  /* arrow icon */
  left: 18px;
  background-position: 0 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--colour-text, #000000);
  -webkit-mask-image: url(caret.1480fc65.svg);
  mask-image: url(caret.1480fc65.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center !important;
  mask-position: center !important;
}
ul.cd-accordion-menu.animated label::before {
  /* this class is used if you're using jquery to animate the accordion */
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-accordion-menu input[type=checkbox]:checked + label::before {
  /* rotate arrow */
  -webkit-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.cd-accordion-menu input[type=checkbox]:checked + label + ul,
.cd-accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
  /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
  /* show children when item is checked */
  display: block;
}
.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
  box-shadow: none;
}
li.menu-section-heading {
  display: block;
  font-weight: 300;
  margin: 20px 0 10px 0px;
  background-color: var(--colour-nav-border, #fff);
  color: var(--colour-text, #000000);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  overflow: hidden;
}
li.menu-section-divider {
  display: block;
  height: 0px;
  margin: 10px 0 10px 0px;
  overflow: hidden;
  border-bottom: 1px dashed var(--colour-nav-border, #fff);
}
span.menu-section-heading {
  display: inline-block;
  background-color: var(--colour-nav-border, #fff);
  padding: 2px 4px;
}
a.currentPage {
  text-shadow: 2px 2px 0px var(--colour-nav-border, #fff);
}
div.menu-panel-placeholder {
  display: block;
  clear: both;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 0 0 10px;
  color: var(--colour-text, #fff);
  height: 290px;
  text-align: center;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
}
div.centre-box {
  grid-column: 1/3;
  grid-row: 1/4;
  display: block;
  align-self: center;
  justify-self: center;
  padding-bottom: 100px;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}
div.centre-box.wide {
  width: 700px;
}
.center {
  text-align: center;
}
div.centre-box a {
  color: #000 !important;
}
img.squiggle {
  display: block;
  width: 50%;
  margin: 4em auto;
}
span.loginlabel {
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.login input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 0 0;
  border: 0;
  padding: 10px;
}
.login button {
  display: block;
  width: auto;
  float: right;
  margin: 0 0 0 0;
  border: 0;
  padding: 10px 20px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 14px;
  font-weight: 700;
  background-color: var(--colour-login-button, #000);
  color: var(--colour-login-button-text, var(--colour-main, #efefef));
  cursor: pointer;
}
.login button:hover {
  background-color: var(--colour-login-button-hover, #000);
  color: var(--colour-login-button-text-hover, #fff);
}
.login .error {
  display: block;
  width: 100%;
  padding-top: 20px;
  text-align: center;
  clear: both;
  color: red;
  font-family: var(--sans-serif-stack, sans-serif);
}
.login .error strong {
  display: block;
  margin-bottom: 10px;
}
.login .error a {
  color: red !important;
}
.ms-container {
  width: 100%;
}
/*.ms-container:after{
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  font-size: 0;
  clear: both;
  min-height: 0;
  visibility: hidden;
}
*/
.ms-container .ms-selectable {
  margin-bottom: 20px;
}
div#cp.compact .ms-container .ms-selectable {
  margin-bottom: 10px;
}
.ms-container .ms-selectable:before, .ms-container .ms-selection:before {
  display: block;
  font-weight: 700;
  background-color: #efefef !important;
  color: #000;
  padding-bottom: 10px;
}
.ms-container .ms-list {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  border: 1px solid #ccc;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  height: 100px;
  padding: 0;
  overflow-y: auto;
}
.ms-selection .ms-list {
  height: auto;
  min-height: 20px;
  max-height: 100px;
  margin-bottom: 20px;
}
.ms-container .ms-list.ms-focus {
  outline: 0;
}
.ms-container ul {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.ms-container .ms-optgroup-container {
  width: 100%;
}
.ms-container .ms-optgroup-label {
  margin: 0;
  padding: 5px 0px 0px 5px;
  cursor: pointer;
  color: #999;
}
.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  border-bottom: 1px #eee solid;
  padding: 2px 10px;
  color: #555;
  font-size: 14px;
}
.ms-container .ms-selectable li:hover,
.ms-container .ms-selection li:hover {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  background-color: #08c;
}
.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled {
  background-color: #efefef;
  color: #444;
  cursor: text;
}
div.info {
  padding-bottom: 20px;
}
div.info div {
  width: 600px;
  max-width: 100%;
}
div.info div ul {
  list-style-type: disc;
}
div.info div ul.questions {
  font-family: var(--sans-serif-stack, sans-serif);
}
div.info div ul.questions li a {
  text-decoration: none;
}
div.info div ul.questions li a:hover {
  background-color: #eee;
}
div.info div ul.questions li ul li a {
  text-decoration: none;
}
div.info div ul.questions li ul li a:hover {
  background-color: #eee;
}
span.MathJax_CHTML {
  font-size: 18px !important;
}
ol.literature {
  list-style-type: none;
  font-size: 0.8rem;
  padding-left: 20px;
}
ol.literature li {
  margin-bottom: 10px;
}
ol.literature li a {
  text-decoration: none;
}
ol.literature li a:hover {
  background-color: #eee;
}
ol.resilience-score {
  list-style-type: decimal;
  font-size: 0.8rem;
  padding-left: 20px;
}
/*
F hsla(103, 71%, 41%, 1)
M hsla(267, 86%, 55%, 1)
D hsla(199, 100%, 50%, 1)
T hsla(14, 100%, 50%, 1)
P hsla(199, 18%, 33%, 1)
*/
div#comments_container {
  width: 100%;
  padding-bottom: 10px;
}
div.comment {
  display: block;
  overflow-wrap: break-word;
  width: calc(100% / 3 - 30px);
  padding: 10px;
  border-right: 10px solid #fff;
  border-bottom: 10px solid #fff;
  float: left;
  background-color: var(--comment-background, #fff4c8);
}
div.comment .grade {
  display: inline-block;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
  margin: 0 0 0 20px;
  font-size: 12px;
}
div.comment.F {
  background-color: var(--colour-female-cellophane, rgba(72, 179, 30, 0.4));
}
div.comment.F .grade {
  color: var(--colour-female, #48b31e);
}
div.comment.M {
  background-color: var(--colour-male-cellophane, rgba(130, 42, 239, 0.4));
}
div.comment.M .grade {
  color: var(--colour-male, #822aef);
}
div.comment.D {
  background-color: var(--colour-other-cellophane, rgba(0, 174, 255, 0.4));
}
div.comment.D .grade {
  color: var(--colour-other, #00aeff);
}
div.comment.O {
  background-color: var(--colour-other-cellophane, rgba(255, 60, 0, 0.4));
}
div.comment.O .grade {
  color: var(--colour-other, #ff3c00);
}
div.comment.P {
  background-color: var(--colour-prefer-cellophane, rgba(69, 90, 99, 0.4));
}
div.comment.P .grade {
  color: var(--colour-prefer, #455a63);
}
div.button.gender.active.F {
  background-color: rgba(72, 179, 30, 0.4);
}
div.button.gender.active.M {
  background-color: rgba(130, 42, 239, 0.4);
}
div.button.gender.active.D {
  background-color: rgba(0, 174, 255, 0.4);
}
div.button.gender.active.O {
  background-color: rgba(255, 60, 0, 0.4);
}
div.button.gender.active.P {
  background-color: rgba(69, 90, 99, 0.4);
}
rect {
  stroke-width: 1;
}
rect.male {
  fill: var(--colour-male-translucent, rgba(130, 42, 239, 0.7));
  stroke: var(--colour-male, #822aef);
}
rect.female {
  fill: var(--colour-female-translucent, rgba(72, 179, 30, 0.7));
  stroke: var(--colour-female, #48b31e);
}
.avgsCore path {
  stroke-width: 6;
  fill: none;
}
.avgsCore path.female {
  stroke: var(--colour-female, #48b31e);
}
.avgsCore path.male {
  stroke: var(--colour-male, #822aef);
}
.avgsComp path {
  stroke-width: 3;
  fill: none;
}
.avgsComp path.female {
  stroke: red;
}
.avgsComp path.male {
  stroke: red;
}
g.scaffold path {
  z-index: -2;
}
g.tick {
  z-index: 99;
}
text#temp {
  font-size: 16px;
  font-family: var(--monospace-stack, monospace);
  font-weight: bold;
  text-shadow: -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}
text#temp.female {
  fill: var(--colour-female, #48b31e);
}
text#temp.male {
  fill: var(--colour-male, #822aef);
}
.complines path {
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 10, 10;
  stroke: red;
}
.snapshot-table {
  margin: 0 0 20px 0;
}
.snapshot-table .row {
  display: grid;
  grid-template-columns: 1fr repeat(2, 80px);
  border-bottom: 1px solid #ccc;
}
.snapshot-table .row .item {
  padding: 5px 10px;
  font-size: 0.9rem;
}
.snapshot-table .row .data {
  font-family: var(--monospace-stack, monospace);
  text-align: center;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.snapshot-table .row.header {
  font-family: var(--sans-serif-stack, sans-serif);
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
}
.snapshot-table .row.header > div {
  padding: 5px 10px;
}
.snapshot-table .row.header .item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.snapshot-table .row.header .data {
  font-family: unset;
  font-weight: 300;
}
.data.diff-15 {
  background-color: var(--colour-diff-15);
  border-top: 1px solid var(--colour-diff-15);
}
.data.diff-10 {
  background-color: var(--colour-diff-10);
  border-top: 1px solid var(--colour-diff-10);
}
.data.diff-5 {
  background-color: var(--colour-diff-5);
  border-top: 1px solid var(--colour-diff-5);
}
.data.diff-0 {
  background-color: var(--colour-diff-0);
  border-top: 1px solid var(--colour-diff-0);
}
.data.diff0 {
  background-color: var(--colour-diff0);
  border-top: 1px solid var(--colour-diff0);
}
.data.diff5 {
  background-color: var(--colour-diff5);
  border-top: 1px solid var(--colour-diff5);
}
.data.diff10 {
  background-color: var(--colour-diff10);
  border-top: 1px solid var(--colour-diff10);
}
.data.diff15 {
  background-color: var(--colour-diff15);
  border-top: 1px solid var(--colour-diff15);
}
.snapshot-text {
  width: auto;
  margin: 10px 50px 20px 50px;
  text-align: center;
  font-style: italic;
}
.snapshot-text p {
  line-height: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}
table.snapshot {
  width: 100%;
  text-align: left;
  border: 0px solid #efefef;
  margin: 0 0 24px 0;
}
table.snapshot th {
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
  font-size: 15px;
  border: none;
  padding: 2px 0 4px 0;
  font-family: Arial, Helvetica, sans-serif;
}
table.snapshot th.reporting-area {
  width: 150px;
  padding-left: 0;
}
table.snapshot th.data {
  text-align: center;
  width: 72px;
}
table.snapshot td {
  font-family: var(--serif-stack, serif);
  font-size: 15px;
  padding: 4px 6px;
}
table.snapshot td.domain {
  background-color: #efefef;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}
table.snapshot td.data {
  text-align: center;
  font-family: var(--monospace-stack, monospace);
  font-size: 16px;
  width: 60px;
}
table.snapshot td.first-col {
  width: 150px;
  padding-left: 0;
  text-align: center;
}
table.snapshot td.reporting-area {
  vertical-align: text-top;
  width: 150px;
  padding-left: 0;
}
table.snapshot td.factor-score {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1px;
}
table.snapshot td.title {
  background-color: #f6f6f6;
}
table.snapshot tr {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
table.snapshot tr:nth-child(even) {
  background-color: transparent;
}
table.snapshot tr:hover {
  background-color: #efefef;
}
table.snapshot tr:hover td.reporting-area {
  background-color: #fff;
}
table.snapshot a.snapshot-link {
  display: block;
  position: relative;
  width: calc(100% + 12px);
  text-decoration: none;
  color: #000;
  height: 24px;
  line-height: 24px;
  margin: -4px -6px -4px -6px;
  z-index: 0;
}
table.snapshot a.snapshot-link:hover::after {
  content: "go to detailed view";
  position: absolute;
  top: 2;
  right: 0;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin: 0 10px 0 0;
  padding-left: 20px;
  line-height: 24px;
  color: #444;
  text-decoration: underline;
  text-decoration-color: var(--colour-main, #efefef);
  background-image: linear-gradient(to right, rgba(239, 239, 239, 0), #efefef 20px);
  z-index: 99;
}
div.snapshot-header {
  display: block;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
}
div.snapshot-header table {
  margin-bottom: 10px;
}
div.snapshot-header table tr {
  border: none;
  border-bottom: 1px solid #000;
}
div.snapshot-header table tr:hover {
  background-color: transparent;
}
div.snapshot-header table th {
  padding-top: 20px;
}
span.snapshot-key {
  display: block;
  float: left;
  font-family: var(--monospace-stack, monospace);
  font-size: 15px;
  font-weight: 700;
  margin: 0 20px 0 0;
  width: 60px;
  text-decoration: none;
  color: #000;
}
table.snapshot-legend {
  width: auto;
  margin: 0 auto 20px auto;
  text-align: center;
  border: 0;
  border-collapse: collapse;
}
table.snapshot-legend tr {
  background-color: transparent !important;
}
table.snapshot-legend td {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  padding: 4px 6px;
}
table.snapshot-legend td.key {
  border: 0;
  width: 80px;
  font-family: Arial, Helvetica, sans-serif;
}
table.snapshot-legend td.scale-left {
  text-align: left;
  border-right: 2px solid #000;
  padding-left: 0;
}
table.snapshot-legend td.scale-right {
  text-align: right;
  border-left: 2px solid #000;
  padding-right: 0;
}
table.snapshot-legend td.key {
  border-top: none !important;
  width: 80px;
}
table.snapshot-legend td.key-left {
  border-right: 2px solid #000;
}
table.snapshot-legend td.key-right {
  border-left: 2px solid #000;
}
table.snapshot-legend td.leftcol {
  width: 80px;
}
td.diff-15 {
  background-color: var(--colour-diff-15);
  border-top: 1px solid var(--colour-diff-15);
}
td.diff-10 {
  background-color: var(--colour-diff-10);
  border-top: 1px solid var(--colour-diff-10);
}
td.diff-5 {
  background-color: var(--colour-diff-5);
  border-top: 1px solid var(--colour-diff-5);
}
td.diff-0 {
  background-color: var(--colour-diff-0);
  border-top: 1px solid var(--colour-diff-0);
}
td.diff0 {
  background-color: var(--colour-diff0);
  border-top: 1px solid var(--colour-diff0);
}
td.diff5 {
  background-color: var(--colour-diff5);
  border-top: 1px solid var(--colour-diff5);
}
td.diff10 {
  background-color: var(--colour-diff10);
  border-top: 1px solid var(--colour-diff10);
}
td.diff15 {
  background-color: var(--colour-diff15);
  border-top: 1px solid var(--colour-diff15);
}
.explore {
  display: block;
  padding: 20px;
  border: 1px solid #000;
  margin-bottom: 20px;
  text-decoration: none;
}
.blurb {
  margin: 0 0 20px 0;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 1rem;
  width: 800px;
  max-width: 100%;
  line-height: 1.6rem;
}
.blurb em {
  font-style: normal;
  background-color: #fedb4a22;
  text-decoration: underline;
  text-decoration-color: #fedb4a;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
}
div.example-box {
  margin: 20px 0 40px 0;
  font-family: var(--sans-serif-stack, sans-serif);
}
div.example-box h2 {
  display: block;
  margin: 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: var(--p);
}
div.example-box .examples {
  display: flex;
  flex-wrap: wrap;
}
div.example-box .examples > div {
  background-color: #f6f6f6;
  font-size: 0.9rem;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 var(--p) var(--p) 0;
  cursor: pointer;
}
div.example-box .examples > div:hover {
  background-color: var(--colour-main, #efefef);
}
.factors-table {
  padding: 0 0 20px 0;
  font-family: var(--sans-serif-stack, sans-serif);
}
.factors-table .row {
  display: grid;
  grid-template-columns: 0.4fr 0.4fr 0.2fr;
  border-radius: 10px;
  padding: 5px 0;
  margin-bottom: 10px;
}
.factors-table .row.header {
  font-family: var(--sans-serif-stack, sans-serif);
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
.factors-table .row.header > div {
  padding-left: 15px;
}
.factors-table .row .targets {
  padding-left: 40px;
  opacity: 0.6;
}
.factors-table .row .factor {
  grid-column: 3/4;
  grid-row: 1/2;
  font-weight: 600;
  padding: 5px 15px;
}
.factors-table .row .item {
  grid-column: 1/2;
  padding: 5px 20px 5px 15px;
  border-top: 1px solid #fff;
}
.factors-table .row .item:nth-child(1) {
  border-top: none;
}
.factors-table .row .strength-challenge {
  grid-column: 2/3;
  padding: 5px 0 5px 15px;
  border-top: 1px solid #fff;
}
.factors-table .row .strength-challenge:nth-child(1), .factors-table .row .strength-challenge:nth-child(2) {
  border-top: none;
}
div.gemfinder {
  padding-bottom: 20px;
}
div.gemfinder header {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: end;
  grid-row-gap: 5px;
  padding: 0;
  width: 100%;
  margin: 20px 0 10px 0;
}
div.gemfinder header h2 {
  margin: 0;
}
div.gemfinder header span.better {
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: uppercase;
  align-self: center;
  text-align: right;
  grid-column: 2/3;
  grid-row: 1/3;
  font-family: var(--sans-serif-stack, sans-serif);
}
div.gemfinder header span.subtitle {
  grid-column: 1/2;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 0.9rem;
}
div.gemfinder header button {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  font-size: 20px;
  width: auto;
  color: #000;
  cursor: pointer;
  position: relative;
}
div.gemfinder header button .tooltip {
  visibility: hidden;
  content: attr(data-label);
  position: absolute;
  top: calc(100% - 25px);
  right: calc(100% + 80px);
  transform: translateX(50%);
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
  z-index: 99;
}
div.gemfinder header button:hover .tooltip {
  visibility: visible;
}
div.gemfinder .not-found {
  width: 100%;
  display: grid;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border: 1px solid #efefef;
  font-family: var(--monospace-stack, monospace);
  border-radius: 5px;
  margin: 20px 0 0 0;
}
div.gemfinder .not-found.clickable {
  cursor: pointer;
}
div.gemfinder .not-found.clickable:hover {
  border-color: #aaa;
}
div.gemfinder .item {
  border-top: 1px solid #f6f6f6;
  font-family: var(--sans-serif-stack, sans-serif);
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 20px;
}
div.gemfinder .item.expanded {
  background-color: #fcfcfc;
  margin: 0 -20px;
  padding: 0 20px;
  border-top: 1px solid #efefef;
}
div.gemfinder .item .row {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 20px 1fr auto;
  grid-column-gap: 20px;
  cursor: pointer;
  padding: 10px 0;
}
div.gemfinder .item .row .caret, div.gemfinder .item .row .significance {
  justify-self: center;
  grid-column: 1/2;
  grid-row: 1/2;
}
div.gemfinder .item .row.sig-high .caret, div.gemfinder .item .row.sig-high .significance {
  color: #0d47a1;
}
div.gemfinder .item .row.sig-med .caret, div.gemfinder .item .row.sig-med .significance {
  color: #2196f3;
}
div.gemfinder .item .row.sig-low .caret, div.gemfinder .item .row.sig-low .significance {
  color: #bbdefb;
}
div.gemfinder .item .row.sig-none .significance {
  color: #f6f6f6;
}
div.gemfinder .item .row .caret {
  display: none;
}
div.gemfinder .item .row:hover .caret {
  display: block;
}
div.gemfinder .item .row:hover .significance {
  display: none;
}
div.gemfinder .item .row .direction {
  margin: -10px 0;
  align-items: start;
  padding-top: 8px;
  display: flex;
}
div.gemfinder .item .row .direction span.A, div.gemfinder .item .row .direction span.B {
  font-weight: 700;
  width: 25px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  margin: 0 5px;
  padding: 2px 0;
}
div.gemfinder .item .row .direction span.A:last-child, div.gemfinder .item .row .direction span.B:last-child {
  margin-right: 0;
}
div.gemfinder .item .row .direction span.A {
  background-color: #fedb4a;
}
div.gemfinder .item .row .direction span.B {
  background-color: #c0ca33;
}
div.gemfinder .item .details {
  grid-column: 2/3;
  padding: 0 20px 10px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: start;
}
div.gemfinder .item .details dl {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  margin: 0 0 var(--p) 0;
  color: #444;
  font-size: 0.8rem;
}
div.gemfinder .item .details dl dt, div.gemfinder .item .details dl dd {
  display: block;
  margin: 0;
  padding: 0;
}
div.gemfinder .item .details dl dt a, div.gemfinder .item .details dl dt.button, div.gemfinder .item .details dl dd a, div.gemfinder .item .details dl dd.button {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
div.gemfinder .item .details dl dt a:hover span, div.gemfinder .item .details dl dt.button:hover span, div.gemfinder .item .details dl dd a:hover span, div.gemfinder .item .details dl dd.button:hover span {
  text-decoration: underline;
}
div.gemfinder .item .details dl dt::after, div.gemfinder .item .details dl dd::after {
  content: "·";
  margin: 0 0 0 var(--p);
}
div.gemfinder .item .details dl dt:last-of-type::after, div.gemfinder .item .details dl dd:last-of-type::after {
  display: none;
}
div.gemfinder .item .details dl dt {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 10px;
}
div.gemfinder .item .details dl dt .katex {
  text-transform: lowercase;
}
div.gemfinder .item .details dl dd {
  font-family: var(--monospace-stack, monospace);
}
div.gemfinder .item .details dl dd.text {
  font-size: 0.9rem;
  font-weight: 700;
}
div.gemfinder .item .details dl dd::after {
  content: "·";
  margin: 0 var(--p);
}
div.gemfinder .item .details dl dd:last-of-type::after {
  display: none;
}
div.gemfinder .item .details .plain-language {
  font-size: 1.2rem;
  max-width: 600px;
  margin-bottom: 20px;
}
div.gemfinder .item .details .plain-language::before {
  content: "For example, ";
}
div.gemfinder .item .details .plain-language span.A, div.gemfinder .item .details .plain-language span.B {
  font-weight: 700;
  width: 25px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  margin-top: 2px;
}
div.gemfinder .item .details .plain-language span.A {
  background-color: #fedb4a;
}
div.gemfinder .item .details .plain-language span.B {
  background-color: #c0ca33;
}
div.gemfinder .item .details .table {
  display: grid;
  grid-template-columns: auto 1fr;
}
div.gemfinder .item .details .table span:nth-of-type(2n+1) {
  padding-right: 20px;
}
div.gemfinder .item .details .table span:nth-of-type(2n) {
  font-family: var(--monospace-stack, monospace);
}
div.gemfinder .item .details svg {
  grid-column: 2/3;
}
div.gemfinder h2 {
  margin: 20px 0 10px 0;
}
.slide h2 {
  margin-top: 20px;
}
.slide .blurb {
  margin-bottom: 10px;
}
nav.slides {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-column-gap: 20px;
  width: 100%;
  padding: 20px 0 20px 0;
  background-color: transparent;
}
nav.slides button {
  border: none;
  display: block;
  border-radius: var(--border-radius);
  padding: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: var(--sans-serif-stack, sans-serif);
  background-color: var(--colour-main, #efefef);
  border: 1px solid var(--colour-main, #efefef);
  opacity: 0.7;
}
nav.slides button:hover {
  opacity: 1;
}
nav.slides button.inert {
  cursor: default;
  opacity: 0.1;
}
div.helper-pages-option {
  float: left;
  border: 4px solid var(--colour-cp-highlight, var(--colour-main, #efefef));
  margin: 10px 20px 20px 0;
  padding: 20px;
  text-align: center;
}
div.helper-pages-option.third-width {
  width: calc((100% - 160px - 24px) / 3);
}
div.helper-pages-option.half-width {
  width: calc((100% - 100px - 16px) / 2);
}
div.helper-pages-option.last-of-type {
  margin: 10px 0 0 0;
}
span.helper-pages-option-icon {
  display: block;
  font-size: 2rem;
  margin: 0 0 10px 0;
}
span.helper-pages-option-name {
  display: inline-block;
  font-size: 1.1rem;
  font-family: var(--sans-serif-stack, sans-serif);
  text-decoration: none;
  /*background-color: $colour-main;*/
  margin: 0 0 20px 0;
}
span.helper-pages-option-description {
  display: block;
  text-decoration: none;
  margin: 0px 0 20px 0;
}
span.helper-pages-option-description.last-of-type {
  margin: 0;
}
span.helper-pages-option-description strong {
  font-weight: 700;
  font-family: inherit;
}
span.helper-pages-option-description em {
  font-style: italic;
}
a:hover div.helper-pages-option {
  background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
}
div.executive-summary-option {
  float: left;
  border: 4px solid var(--colour-cp-highlight, var(--colour-main, #efefef));
  margin: 10px 20px 20px 0;
  padding: 20px;
  text-align: center;
}
div.executive-summary-option.third-width {
  width: calc((100% - 160px - 24px) / 3);
}
div.executive-summary-option.half-width {
  width: calc((100% - 100px - 16px) / 2);
}
div.executive-summary-option.last-of-type {
  margin: 10px 0 0 0;
}
span.executive-summary-option-icon {
  display: block;
  font-size: 2rem;
  margin: 0 0 10px 0;
}
span.executive-summary-option-name {
  display: inline-block;
  font-size: 1.1rem;
  font-family: var(--sans-serif-stack, sans-serif);
  text-decoration: none;
  /*background-color: $colour-main;*/
  margin: 0 0 20px 0;
}
div.executive-summary-option hr {
  display: block;
  width: 100px;
  max-width: 100%;
  height: 1px;
  background-color: #000;
  border: none;
  margin: 20px auto;
}
span.executive-summary-option-description {
  display: block;
  text-decoration: none;
  margin: 0px 0 20px 0;
}
span.executive-summary-option-description.last-of-type {
  margin: 0;
}
span.executive-summary-option-description strong {
  font-weight: 700;
  font-family: inherit;
}
span.executive-summary-option-description em {
  font-style: italic;
}
span.executive-summary-option-table {
  font-family: var(--sans-serif-stack, sans-serif);
}
span.executive-summary-option-smallcaps {
  font-weight: 300;
  font-family: var(--sans-serif-stack, sans-serif);
  font-variant: small-caps;
  letter-spacing: 1px;
}
span.executive-summary-option-monospace {
  display: block;
  font-family: var(--monospace-stack, monospace);
  margin: 6px 0 0 0;
}
span.exsum-1 {
  background-color: rgba(254, 219, 74, 0.2);
}
span.exsum-2 {
  background-color: rgba(254, 219, 74, 0.55);
}
span.exsum-3 {
  background-color: rgba(254, 219, 74, 0.9);
}
span.exsum-4 {
  background-color: rgba(254, 219, 74, 0);
}
a:hover div.executive-summary-option {
  background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
}
a:hover div.executive-summary-option .executive-summary-option-description .executive-summary-option-monospace .exsum-1 {
  background-color: rgba(255, 255, 255, 0.2);
}
a:hover div.executive-summary-option .executive-summary-option-description .executive-summary-option-monospace .exsum-2 {
  background-color: rgba(255, 255, 255, 0.55);
}
a:hover div.executive-summary-option .executive-summary-option-description .executive-summary-option-monospace .exsum-3 {
  background-color: rgba(255, 255, 255, 0.9);
}
.slopegraphLines path {
  fill: none;
  z-index: 10;
}
.slopegraph.xAxis path.domain {
  display: none;
}
text.annotation {
  font-family: var(--monospace-stack, monospace);
  font-weight: 300;
  font-size: 10pt;
}
text.sglab {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  font-size: 10pt;
}
text.sglabhighlight {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 300;
  font-size: 10pt;
}
text.sgpc {
  font-family: var(--monospace-stack, monospace);
  font-weight: 300;
  font-size: 10pt;
  paint-order: stroke;
  stroke: #fff;
  stroke-width: 3px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  z-index: 11;
}
.sb-legend {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 20px 160px;
  text-align: left;
  font-family: var(--monospace-stack, monospace);
  font-weight: 700;
}
.sb-legend .sb-legend-item {
  display: inline-block;
  margin-right: 20px;
}
.sb-table {
  width: 100%;
  margin: 0 0 20px 0;
  font-family: var(--monospace-stack, monospace);
}
.sb-table .sb-row {
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: 70px auto auto auto auto auto auto auto auto;
}
.sb-table .sb-row .sb-cell {
  text-align: center;
  padding: 5px;
}
.sb-table .sb-row .sb-cell-comp {
  font-size: 0.8rem;
}
.sb-table .sb-row .sb-cell:first-child {
  font-weight: 700;
  text-align: right;
  border-right: 1px solid #000;
}
.sb-table .sb-row .sb-cell.sb-cell-comp:first-child {
  font-weight: 300;
}
.sb-table .sb-row:last-child {
  border-top: 1px solid #000;
  font-weight: 700;
}
.sb-table .sb-row-border-top {
  border-top: 2px solid #000;
}
rect.mindset-shading {
  fill: var(--colour-main, #efefef);
  opacity: 0.6;
  stroke-width: 0;
}
text.mindset-percentage {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 64pt;
}
text.mindset-percentage-comp {
  font-size: 54pt !important;
}
text.mindset-percentage-res {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 64pt;
  fill: #65dc18;
}
text.mindset-descript {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 14pt;
}
text.mindset-subtext {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 14pt;
  font-style: italic;
}
text.mindset-class {
  font-family: var(--sans-serif-stack, sans-serif);
  font-weight: 700;
  font-size: 22pt;
}
div.action-plan > div.field {
  display: grid;
  grid-template-columns: 200px auto;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}
div.action-plan > div#ap-buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}
div.action-plan > div#ap-buttons > div {
  margin-bottom: 0;
}
div.action-plan div.meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.action-plan div.step p {
  background-color: #00a34a14;
  border-radius: 15px;
  padding: 20px;
  font-size: 0.85rem;
}
div.action-plan div.default p {
  background-color: #2a83a514;
  border-radius: 15px;
  padding: 20px;
  font-size: 0.85rem;
}
div.action-plan label {
  display: block;
  font-family: var(--sans-serif-stack, sans-serif);
  text-transform: uppercase;
}
div.action-plan label.default, div.action-plan label.step {
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  padding: 5px;
  margin: 0 0 10px 0;
}
div.action-plan label.meta {
  text-align: right;
}
div.action-plan label.meta::after {
  content: ":";
}
div.action-plan label.default {
  background-color: #2a83a5;
}
div.action-plan label.step {
  background-color: #00a34a;
}
div.action-plan p {
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 0.9rem;
}
div.action-plan textarea {
  min-height: 100px;
  resize: vertical;
  border-radius: 0;
  border: none;
  background-color: #f6f6f6;
  padding: 20px;
  font-family: var(--sans-serif-stack, sans-serif);
  font-size: 1rem;
}
div.action-plan textarea:focus {
  outline: none;
}
div.action-plan input[type=date],
div.action-plan input[type=number],
div.action-plan input[type=text] {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0;
  padding: 20px;
  font-family: var(--monospace-stack, monospace);
  font-size: 1rem;
}
div.action-plan input[type=date]:focus,
div.action-plan input[type=number]:focus,
div.action-plan input[type=text]:focus {
  outline: none;
}
div.action-plan input[type=text] {
  font-family: var(--sans-serif-stack, sans-serif);
}
div.action-plan input[type=date]:focus,
div.action-plan input[type=number]:focus,
div.action-plan input[type=text]:focus,
div.action-plan textarea:focus {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
div.action-plan div#ap-save,
div.action-plan div#ap-update,
div.action-plan div#ap-delete {
  grid-row: 1/2;
  margin-bottom: 0;
}
/*# sourceMappingURL=index.5699f645.css.map */
